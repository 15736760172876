import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { message } from 'antd';

// Define Contact Type
export interface Contact {
  _id: string;
  name: string;
  email: string;
  phone: string;
  address?: string;
  city?: string;
  postalCode?: string;
  country?: string;
  documentPath?: string;
}

// Define State Type
interface ContactsState {
  contacts: Contact[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error?: string;
}

// Initial State
const initialState: ContactsState = {
  contacts: [],
  status: 'idle',
};

export const fetchContacts = createAsyncThunk(
  'contacts/fetchContacts',
  async () => {
    const response = await axios.get<Contact[]>(
      `${process.env.REACT_APP_API_BASE_URL}/contacts`,
    );
    return response.data;
  },
);

export const fetchContact = createAsyncThunk(
  'contacts/fetchContact',
  async (id: string) => {
    const response = await axios.get<Contact>(
      `${process.env.REACT_APP_API_BASE_URL}/contacts/${id}`,
    );
    return response.data;
  },
);

export const createContact = createAsyncThunk(
  'contacts/createContact',
  async (contactData: FormData) => {
    const response = await axios.post<Contact>(
      `${process.env.REACT_APP_API_BASE_URL}/contacts`,
      contactData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }, // ✅ Ensure correct headers
      },
    );
    return response.data;
  },
);

export const updateContact = createAsyncThunk(
  'contacts/updateContact',
  async ({ id, contactData }: { id: string; contactData: FormData }) => {
    const response = await axios.put<Contact>(
      `${process.env.REACT_APP_API_BASE_URL}/contacts/${id}`,
      contactData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }, // ✅ Ensure correct headers
      },
    );
    return response.data;
  },
);
const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContacts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchContacts.fulfilled,
        (state, action: PayloadAction<Contact[]>) => {
          state.contacts = action.payload;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchContacts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(
        createContact.fulfilled,
        (state, action: PayloadAction<Contact>) => {
          state.contacts.push(action.payload);
          message.success('Contact created successfully');
        },
      )
      .addCase(createContact.rejected, (_, action) => {
        message.error('Failed to create contact');
        console.error('Error creating contact:', action.payload);
      });
  },
});

export default contactsSlice.reducer;
