import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Upload,
  notification,
  Tabs,
  Spin,
} from 'antd';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvoice, saveInvoice } from '../store/invoiceSlice';
import { fetchContacts } from '../store/contactsSlice';
import { fetchProjects } from '../store/projectSlice';
import { RootState, AppDispatch } from '../store/store';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

const { Option } = Select;
const { Dragger } = Upload;

const CreateInvoice: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const { invoiceData } = useSelector((state: RootState) => state.invoice);
  const contacts = useSelector((state: RootState) => state.contacts.contacts);
  const projects = useSelector((state: RootState) => state.projects.projects);

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any[]>([]);
  const [isInvoiceLoaded, setIsInvoiceLoaded] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurrenceType, setRecurrenceType] = useState<string | null>(null);
  const [recurrenceEndDate, setRecurrenceEndDate] =
    useState<dayjs.Dayjs | null>(null);

  useEffect(() => {
    dispatch(fetchContacts());
    dispatch(fetchProjects());

    if (id) {
      dispatch(fetchInvoice(id));
    } else {
      form.setFieldsValue({
        invoiceDate: dayjs(),
        dueDate: dayjs().add(10, 'day'),
        items: [
          { projectId: undefined, description: '', quantity: 1, price: 0 },
        ],
      });
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!invoiceData || !id || contacts.length === 0 || projects.length === 0)
      return;

    const items = Array.isArray(invoiceData.items)
      ? invoiceData.items.map((item: any) => ({
          projectId: item.projectId,
          quantity: item.quantity,
          price: item.price,
          description: item.description,
        }))
      : [];

    form.setFieldsValue({
      contact: invoiceData.contact?._id,
      invoiceDate: invoiceData.invoiceDate
        ? dayjs(invoiceData.invoiceDate)
        : null,
      dueDate: invoiceData.dueDate ? dayjs(invoiceData.dueDate) : null,
      items,
    });

    if (invoiceData.recurrence && typeof invoiceData.recurrence === 'object') {
      setIsRecurring(true);
      setRecurrenceType(invoiceData.recurrence.type);
      setRecurrenceEndDate(dayjs(invoiceData.recurrence.endDate));
    } else {
      setIsRecurring(false);
      setRecurrenceType(null);
      setRecurrenceEndDate(null);
    }

    if (invoiceData.files) {
      const transformedFiles = invoiceData.files.map(
        (file: any, index: number) => ({
          uid: String(index),
          name: file.name || `file-${index}`,
          status: 'done',
          url: file.url,
        }),
      );
      setFileList(transformedFiles);
    }

    setIsInvoiceLoaded(true);
  }, [invoiceData, id, contacts, projects, form]);

  const handleFileChange = ({ fileList }: any) => {
    setFileList(fileList);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      const totalAmount = values.items.reduce((sum: number, item: any) => {
        const quantity = Number(item.quantity) || 0;
        const price = Number(item.price) || 0;
        return sum + quantity * price;
      }, 0);

      const invoicePayload: any = {
        contact: values.contact,
        items: values.items,
        dueDate: values.dueDate.format('YYYY-MM-DD'),
        invoiceDate: values.invoiceDate.format('YYYY-MM-DD'),
        totalAmount,
        files: fileList.map((file) => file.originFileObj || file),
      };

      if (isRecurring && recurrenceType && recurrenceEndDate) {
        invoicePayload.recurrence = {
          type: recurrenceType,
          endDate: recurrenceEndDate.format('YYYY-MM-DD'),
        };
      }

      dispatch(saveInvoice({ invoiceId: id, invoiceData: invoicePayload }))
        .unwrap()
        .then(() => {
          notification.success({
            message: 'Success',
            description: id
              ? 'Invoice updated successfully'
              : 'Invoice created successfully',
          });
        })
        .catch(() => {
          notification.error({
            message: 'Error',
            description: 'Failed to save invoice',
          });
        });
    } catch (error) {
      notification.error({
        message: 'Validation Failed',
        description: 'Please fill in all required fields.',
      });
    }
  };

  if (id && !isInvoiceLoaded) {
    return (
      <div style={{ padding: 50, textAlign: 'center' }}>
        <Spin size="large" tip="Loading invoice..." />
      </div>
    );
  }

  return (
    <div style={{ maxWidth: '700px', paddingLeft: '20px' }}>
      <h1>{id ? 'Edit Invoice' : 'Create Invoice'}</h1>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: 'Customer',
              children: (
                <Form.Item
                  label="Select Contact"
                  name="contact"
                  rules={[
                    { required: true, message: 'Please select a contact!' },
                  ]}
                >
                  <Select placeholder="Select a contact" showSearch disabled={!!id}>
                    {contacts.map((contact) => (
                      <Option key={contact._id} value={contact._id}>
                        {contact.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ),
            },
            {
              key: '2',
              label: 'Position',
              children: (
                <Form.List name="items">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <div key={key} style={{ marginBottom: '20px' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'projectId']}
                            label={`Project ${index + 1}`}
                            rules={[
                              {
                                required: true,
                                message: 'Please select a project!',
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select a project"
                              showSearch
                              onChange={(projectId) => {
                                const selected = projects.find(
                                  (p) => p._id === projectId,
                                );
                                if (selected) {
                                  form.setFieldValue(
                                    ['items', index, 'description'],
                                    `${selected.name}: ${selected.description}`,
                                  );
                                  form.setFieldValue(
                                    ['items', index, 'price'],
                                    selected.price || 0,
                                  );
                                }
                              }}
                            >
                              {projects.length === 0 ? (
                                <Option disabled value="no-projects">
                                  No projects found
                                </Option>
                              ) : (
                                projects.map((project) => (
                                  <Option key={project._id} value={project._id}>
                                    {project.name}
                                  </Option>
                                ))
                              )}
                            </Select>
                          </Form.Item>

                          <Form.Item name={[name, 'description']} hidden>
                            <Input />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[name, 'quantity']}
                            label={`Quantity ${index + 1}`}
                            rules={[
                              { required: true, message: 'Enter quantity' },
                            ]}
                          >
                            <Input type="number" min={1} />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[name, 'price']}
                            label={`Price ${index + 1}`}
                            rules={[{ required: true, message: 'Enter price' }]}
                          >
                            <Input type="number" min={0} step="0.01" />
                          </Form.Item>
                        </div>
                      ))}
                      <Button
                        type="dashed"
                        icon={<PlusOutlined />}
                        onClick={() =>
                          add({
                            projectId: undefined,
                            description: '',
                            quantity: 1,
                            price: 0,
                          })
                        }
                      >
                        Add Item
                      </Button>
                    </>
                  )}
                </Form.List>
              ),
            },
            {
              key: '3',
              label: 'Extra',
              children: (
                <>
                  <Form.Item
                    label="Invoice Date"
                    name="invoiceDate"
                    rules={[
                      {
                        required: true,
                        message: 'Please select an invoice date!',
                      },
                    ]}
                  >
                    <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                  </Form.Item>

                  <Form.Item
                    label="Due Date"
                    name="dueDate"
                    rules={[
                      { required: true, message: 'Please select a due date!' },
                    ]}
                  >
                    <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                  </Form.Item>

                  <Form.Item label="Recurring Invoice">
                    <Select
                      placeholder="Select Recurrence"
                      value={isRecurring ? recurrenceType : undefined}
                      onChange={(value) => {
                        setIsRecurring(!!value);
                        setRecurrenceType(value);
                      }}
                      allowClear
                    >
                      <Option value="weekly">Weekly</Option>
                      <Option value="monthly">Monthly</Option>
                      <Option value="yearly">Yearly</Option>
                    </Select>
                  </Form.Item>

                  {isRecurring && (
                    <Form.Item label="Recurrence End Date">
                      <DatePicker
                        value={recurrenceEndDate}
                        onChange={setRecurrenceEndDate}
                        format="YYYY-MM-DD"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}

                  <Form.Item name="file" label="Upload File">
                    <Dragger
                      beforeUpload={() => false}
                      onChange={handleFileChange}
                      fileList={fileList}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p>Click or drag file to upload</p>
                    </Dragger>
                  </Form.Item>
                </>
              ),
            },
          ]}
        />

        <Button type="primary" htmlType="submit" style={{ marginTop: '20px' }}>
          {id ? 'Update Invoice' : 'Create Invoice'}
        </Button>
      </Form>
    </div>
  );
};

export default CreateInvoice;
