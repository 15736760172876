import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, notification, Row, Col } from 'antd';
import axios from 'axios';
const { Option } = Select;

export default function CreatePayment() {
  const [form] = Form.useForm();
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);

  // Fetch settings from the API
  const fetchSettings = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/settings`,
      );

      if (response.data && response.data.length > 0) {
        const { accountName, iban, bic, currencies } = response.data[0];

        form.setFieldsValue({
          debtor: {
            name: accountName,
            iban,
            bic,
          },
        });

        setCurrencies(currencies || ['CHF']);
      }
    } catch (err) {
      console.error('Error fetching settings:', err);
      notification.error({
        message: 'Error',
        description: 'Failed to fetch settings.',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  // Add payment row
  const addPayment = () => {
    setPayments([
      ...payments,
      {
        creditorName: '',
        creditorIban: '',
        amount: '',
        currency: currencies[0] || 'CHF',
      },
    ]);
  };

  // Remove payment row
  const removePayment = (index) => {
    const updatedPayments = payments.filter((_, i) => i !== index);
    setPayments(updatedPayments);
  };

  // Handle changes in payments
  const handlePaymentChange = (index, field, value) => {
    const updatedPayments = payments.map((p, i) =>
      i === index ? { ...p, [field]: value } : p,
    );
    setPayments(updatedPayments);
  };

  // Validate payments
  const isFormValid = () => {
    if (payments.length === 0) return false;

    return payments.every(
      (payment) =>
        payment.creditorName &&
        payment.creditorIban &&
        payment.amount > 0 &&
        payment.currency,
    );
  };

  // Generate XML
  const generateXml = async () => {
    try {
      const values = await form.validateFields();
      const { debtor } = values;

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/generate-xml`,
        { debtor, payments },
      );

      const blob = new Blob([response.data], { type: 'application/xml' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'payment.xml');
      document.body.appendChild(link);
      link.click();
      link.remove();

      notification.success({
        message: 'Success',
        description: 'XML generated successfully.',
      });
    } catch (error) {
      console.error('Error generating XML:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to generate XML. Please check your input.',
      });
    }
  };
  /*
  // Start EBICS Transfer
  const startEbicsTransfer = async () => {
    try {
      const values = await form.validateFields();
      const { debtor } = values;

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/ebics-transfer`,
        { debtor, payments },
      );
      notification.success({
        message: 'Success',
        description: 'EBICS transfer successful.',
      });
    } catch (error) {
      console.error('EBICS transfer error:', error);
      notification.error({
        message: 'Error',
        description: 'Transfer failed. Please check your input.',
      });
    }
  };
 */
  return (
    <div style={{ paddingLeft: '20px', maxWidth: '600px' }}>
      <h1>Payment Details</h1>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          debtor: {
            name: '',
            iban: '',
            bic: '',
          },
        }}
      >
        <h3>Debtor Information</h3>
        <Form.Item
          label="Debtor Name"
          name={['debtor', 'name']}
          rules={[{ required: true, message: 'Please enter the debtor name' }]}
        >
          <Input
            readOnly
            style={{ backgroundColor: '#f5f5f5', color: '#888' }}
          />
        </Form.Item>
        <Form.Item
          label="Debtor IBAN"
          name={['debtor', 'iban']}
          rules={[{ required: true, message: 'Please enter the debtor IBAN' }]}
        >
          <Input
            readOnly
            style={{ backgroundColor: '#f5f5f5', color: '#888' }}
          />
        </Form.Item>
        <Form.Item
          label="Debtor BIC"
          name={['debtor', 'bic']}
          rules={[{ required: true, message: 'Please enter the debtor BIC' }]}
        >
          <Input
            readOnly
            style={{ backgroundColor: '#f5f5f5', color: '#888' }}
          />
        </Form.Item>

        <h3>Payments</h3>
        {payments.map((payment, index) => (
          <div
            key={index}
            style={{
              marginBottom: '20px',
              padding: '10px',
              border: '1px solid #ddd',
            }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Creditor Name" required>
                  <Input
                    placeholder="Creditor Name"
                    value={payment.creditorName}
                    onChange={(e) =>
                      handlePaymentChange(index, 'creditorName', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Creditor IBAN" required>
                  <Input
                    placeholder="Creditor IBAN"
                    value={payment.creditorIban}
                    onChange={(e) =>
                      handlePaymentChange(index, 'creditorIban', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Amount" required>
                  <Input
                    type="number"
                    min="0"
                    placeholder="Amount"
                    value={payment.amount}
                    onChange={(e) =>
                      handlePaymentChange(index, 'amount', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Currency" required>
                  <Select
                    value={payment.currency}
                    onChange={(value) =>
                      handlePaymentChange(index, 'currency', value)
                    }
                  >
                    {currencies.map((currency) => (
                      <Option key={currency} value={currency}>
                        {currency}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                <Button type="link" danger onClick={() => removePayment(index)}>
                  Remove Payment
                </Button>
              </Col>
            </Row>
          </div>
        ))}

        <Button
          type="dashed"
          onClick={addPayment}
          style={{ width: '100%', marginBottom: '20px' }}
        >
          Add Payment
        </Button>

        <Button
          type="primary"
          htmlType="button"
          onClick={generateXml}
          loading={loading}
          disabled={!isFormValid()}
          style={{ marginRight: '10px' }}
        >
          Generate XML
        </Button>

        <Button
          type="primary"
          htmlType="button"
          loading={loading}
          disabled={!isFormValid()}
        >
          Start EBICS Transfer
        </Button>
      </Form>
    </div>
  );
}
