import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Button,
  Upload,
  notification,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const { Option } = Select;
const { Dragger } = Upload;

interface Account {
  _id: string;
  accountNumber: string;
  accountName: string;
}

interface FormValues {
  debitAccount: string;
  creditAccount: string;
  date: dayjs.Dayjs;
  description: string;
  amount: number;
  file?: { file: File };
}

const CreateTransaction: React.FC = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get<Account[]>(`${process.env.REACT_APP_API_BASE_URL}/accounts`)
      .then(({ data }) => setAccounts(data))
      .catch(() => notification.error({ message: 'Failed to fetch accounts' }));
  }, []);

  const handleSubmit = async (values: FormValues) => {
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key === 'file' && value) {
        formData.append('document', value.file);
      } else if (key === 'date') {
        formData.append(key, value.format('YYYY-MM-DD'));
      } else {
        formData.append(key, value as string);
      }
    });

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/transactions`,
        formData,
      );
      notification.success({ message: 'Transaction created successfully' });
      navigate('/transactions');
    } catch {
      notification.error({ message: 'Failed to create transaction' });
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{ date: dayjs() }}
      style={{ maxWidth: 600, padding: '0 20px' }}
    >
      <h1>Create Transaction</h1>
      <Form.Item
        name="debitAccount"
        label="Debit Account"
        rules={[{ required: true }]}
      >
        <Select placeholder="Select Debit Account">
          {accounts.map((account) => (
            <Option key={account._id} value={account._id}>
              {`${account.accountNumber} - ${account.accountName}`}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="creditAccount"
        label="Credit Account"
        rules={[{ required: true }]}
      >
        <Select placeholder="Select Credit Account">
          {accounts.map((account) => (
            <Option key={account._id} value={account._id}>
              {`${account.accountNumber} - ${account.accountName}`}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="date" label="Date" rules={[{ required: true }]}>
        <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true }]}
      >
        <Input placeholder="Enter description" />
      </Form.Item>

      <Form.Item name="amount" label="Amount" rules={[{ required: true }]}>
        <InputNumber
          placeholder="Enter amount"
          min={0}
          step={0.01}
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item name="file">
        <Dragger beforeUpload={() => false}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p>Click or drag file to upload</p>
        </Dragger>
      </Form.Item>

      <Button type="primary" htmlType="submit" block>
        Create Transaction
      </Button>
    </Form>
  );
};

export default CreateTransaction;
