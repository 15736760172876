import React, { useEffect } from 'react';
import { Table, Button, Row, Col, message, Popconfirm } from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { fetchProjects, deleteProject } from '../store/projectSlice';

interface Project {
  _id: string;
  name: string;
  description: string;
  status: string;
  contacts: { _id: string; name: string }[];
}

const ProjectForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { projects, loading, error } = useSelector(
    (state: RootState) => state.projects,
  );

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      message.error('Failed to fetch projects');
    }
  }, [error]);

  const handleDelete = (id: string) => {
    dispatch(deleteProject(id))
      .then(() => {
        message.success('Project deleted successfully');
      })
      .catch(() => {
        message.error('Failed to delete project');
      });
  };

  const handleDownload = (id: string) => {
    console.log(`Downloading project ${id}`);
  };

  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      key: 'contacts',
      render: (contacts: Project['contacts']) =>
        contacts.map((contact) => <div key={contact._id}>{contact.name}</div>),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: Project) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Link to={`/projects/edit/${record._id}`}>
            <EditOutlined style={{ color: '#1890ff', cursor: 'pointer' }} />
          </Link>
          <Popconfirm
            title="Are you sure you want to delete this project?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
          </Popconfirm>
          <DownloadOutlined
            style={{
              cursor: 'pointer',
              color: '#1890ff',
              fontSize: '18px',
              marginLeft: '8px',
            }}
            onClick={() => handleDownload(record._id)}
          />
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: '20px' }}
      >
        <Col>
          <h1>Projects Overview</h1>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate('/projects/create')}
          >
            Create Project
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={projects}
        rowKey="_id"
        loading={loading}
      />
    </div>
  );
};

export default ProjectForm;
