import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { fetchInvoices } from '../store/invoiceSlice';
import { Pie } from '@ant-design/plots';
import { Card, Space, Spin } from 'antd';

const Dashboard: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { invoices, status } = useSelector((state: RootState) => state.invoice);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchInvoices());
    }
  }, [dispatch, status]);

  const paid = invoices.filter((invoice) => invoice.status === 'Paid');
  const unpaid = invoices.filter((invoice) => invoice.status !== 'Paid');

  const data = [
    {
      type: 'Paid',
      value: paid.reduce((sum, inv) => sum + inv.totalAmount, 0),
    },
    {
      type: 'Unpaid',
      value: unpaid.reduce((sum, inv) => sum + inv.totalAmount, 0),
    },
  ];

  const config = {
    appendPadding: 2,
    data,
    theme: 'dark',
    angleField: 'value',
    colorField: 'type',
    radius: 0.6,
    innerRadius: 0.4,
    label: {
      offset: '-50%',
      style: {
        textAlign: 'center',
        fill: '#fff',
      },
      formatter: (datum: any) => (datum ? `Betrag: ${datum.value}` : ''),
    },
    statistic: {
      title: false,
      content: {
        style: {
          color: '#fff',
        },
      },
    },
    pieStyle: {
      lineWidth: 0,
    },
    legend: {
      formatter: (text: string, item: any) =>
        `${item.data.type}: ${item.data.value}`,
      itemName: {
        style: {
          fill: '#fff',
        },
      },
    },
  };

  return (
    <div style={{ paddingLeft: '20px', maxWidth: '600px' }}>
      <h1>Dashboard</h1>
      <Space direction="horizontal" size={16}>
        <Card
          title="Invoice Status"
          size="small"
          style={{ width: 400, height: '100%' }}
        >
          {status === 'loading' ? (
            <div style={{ textAlign: 'center', padding: '50px 0' }}>
              <Spin size="large" />
            </div>
          ) : (
            <Pie {...config} />
          )}
        </Card>
      </Space>
    </div>
  );
};

export default Dashboard;
