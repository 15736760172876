import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import {
  fetchTransaction,
  fetchAccounts,
  updateTransaction,
} from '../store/transactionSlice';
import { Form, Input, Button, Select, message, Spin, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Dragger } = Upload;

function EditTransaction() {
  const { transactionId } = useParams<{ transactionId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { transaction, accounts, loading } = useSelector(
    (state: RootState) => state.transaction,
  );

  useEffect(() => {
    if (transactionId) {
      dispatch(fetchTransaction(transactionId));
      dispatch(fetchAccounts());
    }
  }, [dispatch, transactionId]);

  const handleSubmit = async (values: any) => {
    const formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      if (
        key === 'document' &&
        value &&
        typeof value === 'object' &&
        'file' in value
      ) {
        const fileObj = value as { file: File };
        formData.append('document', fileObj.file);
      } else {
        formData.append(key, value as string);
      }
    });

    dispatch(updateTransaction({ transactionId: transactionId!, formData }))
      .unwrap()
      .then(() => {
        message.success('Transaction updated successfully');
        navigate('/transactions');
      })
      .catch((error) => {
        message.error(error);
      });
  };

  if (loading || !transaction) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div
      style={{ alignContent: 'left', paddingLeft: '20px', maxWidth: '600px' }}
    >
      <h1>Edit Transaction</h1>
      <Form
        layout="vertical"
        initialValues={transaction}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Debit Account"
          name="debitAccount"
          rules={[{ required: true }]}
        >
          <Select placeholder="Select a debit account">
            {accounts.map((account) => (
              <Option key={account._id} value={account._id}>
                {account.accountName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Credit Account"
          name="creditAccount"
          rules={[{ required: true }]}
        >
          <Select placeholder="Select a credit account">
            {accounts.map((account) => (
              <Option key={account._id} value={account._id}>
                {account.accountName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
          <Input type="number" step="0.01" />
        </Form.Item>

        <Form.Item label="Upload New Document" name="document">
          <Dragger beforeUpload={() => false}>
            <InboxOutlined />
            <p>Click or drag file to upload</p>
          </Dragger>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update Transaction
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default EditTransaction;
