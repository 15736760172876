import React, { useState, useEffect } from 'react';
import { Upload, Button, Table, notification, Select, Input } from 'antd';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  UploadOutlined,
} from '@ant-design/icons';
import axios from 'axios';

export default function CamtChecker() {
  const [files, setFiles] = useState([]);
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    fetchUploadedFiles();
    fetchAccounts();
  }, []);

  useEffect(() => {
    if (result.length > 0 && accounts.length > 0) {
      const defaultRowData = {};
      result.forEach((record) => {
        const defaultDebitAccount = accounts.find(
          (acc) => acc.accountNumber === '1020',
        );
        const defaultCreditAccount = accounts.find(
          (acc) => acc.accountNumber === '1100',
        );
        defaultRowData[record.invoiceNumber] = {
          selectedDebitAccount: defaultDebitAccount?._id || '',
          selectedCreditAccount: defaultCreditAccount?._id || '',
          transactionReference: `Payment for invoice ${record.invoiceNumber}`,
        };
      });
      setRowData(defaultRowData);
    }
  }, [result, accounts]);

  const fetchUploadedFiles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/uploaded-files`,
      );
      setResult(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching uploaded files:', error);
    }
  };

  const fetchAccounts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/accounts`,
      );
      setAccounts(response.data || []);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const uploadProps = {
    multiple: true,
    beforeUpload: (file) => {
      setFiles((prevFiles) => [...prevFiles, file]);
      return false;
    },
    fileList: files,
  };

  const handleCheckPayments = async () => {
    if (files.length === 0) {
      notification.error({
        message: 'Error',
        description: 'Please upload at least one CAMT file.',
      });
      return;
    }

    setLoading(true);
    const formData = new FormData();
    files.forEach((file) => formData.append('file', file));

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/check-invoice`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
      setResult(response.data.invoices || []);
      setFiles([]); // Clear uploaded files
      fetchUploadedFiles(); // Refresh uploaded files list
      notification.success({
        message: 'Success',
        description: 'CAMT files processed successfully.',
      });
    } catch (error) {
      console.error('Error processing files:', error);
      notification.error({
        message: 'Error',
        description: 'An error occurred while processing the CAMT files.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSetInvoicePaid = async (invoice) => {
    const {
      selectedDebitAccount,
      selectedCreditAccount,
      transactionReference,
    } = rowData[invoice.invoiceNumber] || {};

    if (
      !selectedDebitAccount ||
      !selectedCreditAccount ||
      !transactionReference
    ) {
      notification.error({
        message: 'Error',
        description: 'All fields are required to mark the invoice as paid.',
      });
      return;
    }

    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/invoices/${invoice.invoiceId}`,
        {
          status: 'Paid',
        },
      );

      const transactionData = {
        debitAccount: selectedDebitAccount,
        creditAccount: selectedCreditAccount,
        date: new Date().toISOString(),
        description: transactionReference, // Include the transaction reference in the description
        amount: invoice.amount,
      };

      if (transactionReference) {
        transactionData.transactionReference = transactionReference; // Add only if defined
      }

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/transactions`,
        transactionData,
      );

      notification.success({
        message: 'Success',
        description: 'Invoice processed, transaction recorded.',
      });

      // Refresh uploaded files
      fetchUploadedFiles();
    } catch (error) {
      console.error('Error processing invoice:', error);
      notification.error({
        message: 'Error',
        description: 'An error occurred while processing the invoice.',
      });
    }
  };

  const handleFieldChange = (invoiceNumber, field, value) => {
    setRowData((prev) => ({
      ...prev,
      [invoiceNumber]: {
        ...prev[invoiceNumber],
        [field]: value,
      },
    }));
  };

  const columns = [
    {
      title: 'Match',
      dataIndex: 'matched',
      key: 'matched',
      width: 50,
      render: (matched) =>
        matched ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#ff4d4f" />
        ),
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: 50,
    },
    { title: 'Amount', dataIndex: 'amount', key: 'amount', width: 100 },
    { title: 'Currency', dataIndex: 'currency', key: 'currency', width: 100 },
    {
      title: 'Value Date',
      dataIndex: 'valueDate',
      key: 'valueDate',
      width: 150,
    },
    {
      title: 'Debit Account',
      key: 'debitAccount',
      render: (_, record) => {
        const row = rowData[record.invoiceNumber] || {};
        return (
          <Select
            showSearch
            placeholder="Select Debit Account"
            style={{ width: '100%' }}
            value={row.selectedDebitAccount}
            onChange={(value) =>
              handleFieldChange(
                record.invoiceNumber,
                'selectedDebitAccount',
                value,
              )
            }
            filterOption={(input, option) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          >
            {accounts.map((acc) => (
              <Select.Option
                key={acc._id}
                value={acc._id}
                label={`${acc.accountNumber} - ${acc.accountName}`}
              >
                {acc.accountNumber} - {acc.accountName}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },

    {
      title: 'Credit Account',
      key: 'creditAccount',
      render: (_, record) => {
        const row = rowData[record.invoiceNumber] || {};
        return (
          <Select
            showSearch
            placeholder="Select Credit Account"
            style={{ width: '100%' }}
            value={row.selectedCreditAccount}
            onChange={(value) =>
              handleFieldChange(
                record.invoiceNumber,
                'selectedCreditAccount',
                value,
              )
            }
            filterOption={(input, option) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
          >
            {accounts.map((acc) => (
              <Select.Option
                key={acc._id}
                value={acc._id}
                label={`${acc.accountNumber} - ${acc.accountName}`}
              >
                {acc.accountNumber} - {acc.accountName}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },

    {
      title: 'Transaction Reference',
      key: 'transactionReference',
      render: (_, record) => {
        const defaultTransactionReference =
          record.transactionReference ||
          `Payment for invoice ${record.invoiceNumber}`;
        return (
          <Input
            placeholder="Transaction Reference"
            style={{ width: '100%' }}
            defaultValue={defaultTransactionReference} 
            onChange={(e) =>
              handleFieldChange(
                record.invoiceNumber,
                'transactionReference',
                e.target.value,
              )
            }
          />
        );
      },
    },

    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        const {
          selectedDebitAccount,
          selectedCreditAccount,
          transactionReference,
        } = rowData[record.invoiceNumber] || {};

        const canMarkAsPaid =
          !!selectedDebitAccount &&
          !!selectedCreditAccount &&
          !!transactionReference;

        return (
          <Button
            type="primary"
            onClick={() => handleSetInvoicePaid(record)}
            disabled={!canMarkAsPaid}
          >
            Mark as Paid
          </Button>
        );
      },
    },
  ];

  return (
    <div style={{ paddingLeft: '20px' }}>
      <h1>Receive Payments</h1>
      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined />}>Upload CAMT Files</Button>
      </Upload>
      <Button
        type="primary"
        onClick={handleCheckPayments}
        style={{ marginTop: '20px' }}
        loading={loading}
        disabled={files.length === 0}
      >
        Check Payments
      </Button>
      <Table
        dataSource={Array.isArray(result) ? result : []}
        columns={columns}
        rowKey="invoiceNumber"
        style={{ marginTop: '20px' }}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
}
