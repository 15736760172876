import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Transaction {
  _id: string;
  date: string;
  debitAccount: string;
  creditAccount: string;
  amount: number;
  description: string;
}

interface YearEndState {
  transactions: Transaction[];
  isBlocked: boolean;
  loading: boolean;
}

const initialState: YearEndState = {
  transactions: [],
  isBlocked: false,
  loading: true,
};

const yearEndSlice = createSlice({
  name: 'yearEnd',
  initialState,
  reducers: {
    setTransactions: (state, action: PayloadAction<Transaction[]>) => {
      state.transactions = action.payload;
      state.loading = false;
    },
    setIsBlocked: (state, action: PayloadAction<boolean>) => {
      state.isBlocked = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setTransactions, setIsBlocked, setLoading } =
  yearEndSlice.actions;
export default yearEndSlice.reducer;
