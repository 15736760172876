import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface Contact {
  _id: string;
  name: string;
}

interface Project {
  _id: string;
  name: string;
  description: string;
  status: string;
  price: number;
  contacts: Contact[];
}

interface ProjectState {
  projects: Project[];
  contacts: Contact[];
  projectDetails: Project | null;
  loading: boolean;
  error: string | null;
}

const initialState: ProjectState = {
  projects: [],
  contacts: [],
  projectDetails: null,
  loading: false,
  error: null,
};

export const fetchProjects = createAsyncThunk<Project[]>(
  'projects/fetchProjects',
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/projects`,
    );
    return response.data;
  },
);

export const fetchContacts = createAsyncThunk<Contact[]>(
  'projects/fetchContacts',
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/contacts`,
    );
    return response.data;
  },
);

export const fetchProjectDetails = createAsyncThunk<Project, string>(
  'projects/fetchProjectDetails',
  async (projectId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/projects/${projectId}`,
    );
    return response.data;
  },
);

export const deleteProject = createAsyncThunk(
  'projects/deleteProject',
  async (projectId: string) => {
    await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/projects/${projectId}`,
    );
    return projectId; // Return the ID of the deleted project
  },
);

export const createProject = createAsyncThunk<Project, Omit<Project, '_id'>>(
  'projects/createProject',
  async (projectData) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/projects`,
      projectData,
    );
    return response.data;
  },
);

export const updateProject = createAsyncThunk<
  Project,
  { id: string; projectData: Omit<Project, '_id'> }
>('projects/updateProject', async ({ id, projectData }) => {
  const response = await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/projects/${id}`,
    projectData,
  );
  return response.data;
});

const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = action.payload;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch projects';
      })
      .addCase(fetchContacts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchContacts.fulfilled, (state, action) => {
        state.loading = false;
        state.contacts = action.payload;
      })
      .addCase(fetchContacts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch contacts';
      })
      .addCase(fetchProjectDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProjectDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.projectDetails = action.payload;
      })
      .addCase(fetchProjectDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch project details';
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.projects = state.projects.filter(
          (project) => project._id !== action.payload,
        );
      });
  },
});

export default projectSlice.reducer;
