import React, { useEffect, useState } from 'react';
import { Table, Button, notification, Modal, Checkbox } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { setTransactions, setIsBlocked } from '../store/yearEndSlice';

interface Transaction {
  _id: string;
  date: string;
  debitAccount: string;
  creditAccount: string;
  amount: number;
  description: string;
  blocked?: boolean;
}

const YearEnd: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const transactions = useSelector(
    (state: RootState) => state.yearEnd.transactions,
  );
  const isBlocked = useSelector((state: RootState) => state.yearEnd.isBlocked);
  const loading = useSelector((state: RootState) => state.yearEnd.loading);
  const [selectedTransactions, setSelectedTransactions] = useState<string[]>(
    [],
  );

  useEffect(() => {
    dispatch(fetchTransactionsAsync());
  }, [dispatch]);

  const fetchTransactionsAsync = () => async (dispatch: AppDispatch) => {
    try {
      const { data } = await axios.get<Transaction[]>(
        `${process.env.REACT_APP_API_BASE_URL}/transactions?year=${dayjs().subtract(1, 'year').year()}`,
      );
      dispatch(setTransactions(data));
    } catch {
      notification.error({ message: 'Failed to fetch transactions' });
    }
  };

  const handleYearEnd = async () => {
    Modal.confirm({
      title: 'Confirm Year-End Process',
      content:
        'Are you sure you want to close the year? This will block further bookings.',
      onOk: async () => {
        try {
          await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/transactions/year-end`,
            { transactions: selectedTransactions },
          );
          notification.success({ message: 'Year-End process completed' });
          dispatch(setIsBlocked(true));
          dispatch(
            setTransactions(
              transactions.map((transaction) =>
                selectedTransactions.includes(transaction._id)
                  ? { ...transaction, blocked: true }
                  : transaction,
              ),
            ),
          );
        } catch {
          notification.error({ message: 'Year-End process failed' });
        }
      },
    });
  };

  const handleSelectTransaction = (transactionId: string) => {
    setSelectedTransactions((prev) =>
      prev.includes(transactionId)
        ? prev.filter((id) => id !== transactionId)
        : [...prev, transactionId],
    );
  };

  const columns = [
    {
      title: 'Select',
      dataIndex: '_id',
      render: (id: string) => (
        <Checkbox
          checked={selectedTransactions.includes(id)}
          onChange={() => handleSelectTransaction(id)}
        />
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text: string) => dayjs(text).format('DD-MM-YYYY'),
    },
    { title: 'Debit Account', dataIndex: 'debitAccount' },
    { title: 'Credit Account', dataIndex: 'creditAccount' },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount: number) => `CHF ${amount.toFixed(2)}`,
    },
    { title: 'Description', dataIndex: 'description' },
  ];

  return (
    <div style={{ padding: 20 }}>
      <h1>Year-End Transactions</h1>
      <Table
        dataSource={transactions}
        columns={columns}
        rowKey="_id"
        loading={loading}
      />
      <Button
        type="primary"
        onClick={handleYearEnd}
        disabled={isBlocked}
        style={{ marginTop: 20 }}
      >
        {isBlocked ? 'Year-End Completed' : 'Execute Year-End'}
      </Button>
    </div>
  );
};

export default YearEnd;
