import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

interface Transaction {
  _id: string;
  description: string;
  debitAccount: string;
  creditAccount: string;
  amount: number;
  document?: string;
}

interface Account {
  _id: string;
  accountName: string;
  accountNumber: string;
}

interface TransactionState {
  transaction: Transaction | null;
  accounts: Account[];
  loading: boolean;
  error: string | null;
}

const initialState: TransactionState = {
  transaction: null,
  accounts: [],
  loading: false,
  error: null,
};

// **Async Thunks**
export const fetchTransaction = createAsyncThunk(
  'transaction/fetchTransaction',
  async (transactionId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/transactions/${transactionId}`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to fetch transaction');
    }
  },
);

export const fetchAccounts = createAsyncThunk(
  'transaction/fetchAccounts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/accounts`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to fetch accounts');
    }
  },
);

export const updateTransaction = createAsyncThunk(
  'transaction/updateTransaction',
  async (
    { transactionId, formData }: { transactionId: string; formData: FormData },
    { rejectWithValue },
  ) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/transactions/${transactionId}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
      return true;
    } catch (error) {
      return rejectWithValue('Failed to update transaction');
    }
  },
);

// **Slice Definition**
const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransaction.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchTransaction.fulfilled,
        (state, action: PayloadAction<Transaction>) => {
          state.transaction = action.payload;
          state.loading = false;
        },
      )
      .addCase(fetchTransaction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchAccounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchAccounts.fulfilled,
        (state, action: PayloadAction<Account[]>) => {
          state.accounts = action.payload;
          state.loading = false;
        },
      )
      .addCase(fetchAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateTransaction.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateTransaction.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  },
});

export default transactionSlice.reducer;
