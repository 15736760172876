import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { Layout, Menu, Drawer } from 'antd';
import {
  BookOutlined,
  ContactsOutlined,
  WalletOutlined,
  SettingOutlined,
  LogoutOutlined,
  BankOutlined,
  ProjectOutlined,
} from '@ant-design/icons';
import Keycloak from 'keycloak-js';
import axios from 'axios';
import CreateInvoice from './Invoices/CreateInvoice';
import Start from './Start/Start';
import Contacts from './Contacts/Contacts';
import CreateContacts from './Contacts/CreateContact';
import InvoicesOverview from './Invoices/Invoices';
import TransactionList from './Accounting/TransactionList';
import EditTransaction from './Accounting/EditTransaction';
import CreateTransaction from './Accounting/CreateTransaction';
import ProjectForm from './Projects/Projects';
import CreateProject from './Projects/CreateProject';
import CreatePayment from './Payment/CreatePayment';
import Overview from './Accounting/Overview';
import Setting from './Settings/Settings';
import CamtChecker from './Payment/CamtChecker';
import BreadcrumbComponent from './Breadcrumb';
import YearEnd from './Accounting/CreateYearEnding';
const { Header, Sider, Content } = Layout;

const menuItems = [
  {
    key: 'contacts',
    label: 'Contacts',
    icon: <ContactsOutlined />,
    children: [
      { key: 'contacts/create', label: 'Create Contact', hidden: true },
      { key: 'contacts/edit', label: 'Edit Contact', hidden: true },
    ],
  },
  {
    key: 'invoices',
    label: 'Invoices',
    icon: <WalletOutlined />,
    children: [
      { key: 'invoices/create', label: 'Create Invoice', hidden: true },
      { key: 'invoices/edit', label: 'Edit Invoice', hidden: true },
    ],
  },
  {
    key: 'projects',
    label: 'Projects',
    icon: <ProjectOutlined />,
    children: [
      { key: 'projects/create', label: 'Create Project', hidden: true },
      { key: 'projects/edit', label: 'Edit Project', hidden: true },
    ],
  },

  {
    key: 'accounting',
    label: 'Accounting',
    icon: <BookOutlined />,
    children: [
      { key: 'accounting/transactions', label: 'Transactions', hidden: false },
      {
        key: 'accounting/transactions/create',
        label: 'Create Transaction',
        hidden: true,
      },
      { key: 'accounting/overview', label: 'Balance & Income', hidden: false },
      {
        key: 'accounting/year-end-closing',
        label: 'Year End Closing',
        hidden: false,
      },
    ],
  },
  {
    key: 'payments',
    label: 'Payments',
    icon: <BankOutlined />,
    children: [
      { key: 'payments/create', label: 'Create ', hidden: false },
      { key: 'payments/receive', label: 'Receive', hidden: false },
    ],
  },
];

const generateBreadcrumbItems = (menuItems, currentPath) => {
  const breadcrumbItems = [
    { label: 'Home', path: '/', active: currentPath === '/' },
  ];

  menuItems.forEach((menu) => {
    if (menu.children) {
      menu.children.forEach((child) => {
        if (`/${child.key}` === currentPath) {
          breadcrumbItems.push(
            { label: menu.label, path: `/${menu.key}`, active: false },
            { label: child.label, path: `/${child.key}`, active: true },
          );
        }
      });
    }
  });

  menuItems.forEach((menu) => {
    if (menuItems.children) {
      menu.children.forEach((child) => {
        if (`/${child.key}` === currentPath && !child.hidden) {
          breadcrumbItems.push(
            { label: menu.label, path: `/${menu.key}`, active: false },
            { label: child.label, path: `/${child.key}`, active: true },
          );
        }
      });
    } else if (`/${menu.key}` === currentPath) {
      breadcrumbItems.push({
        label: menu.label,
        path: `/${menu.key}`,
        active: true,
      });
    }
  });

  return breadcrumbItems;
};

const KeycloakContext = createContext();

export const useKeycloak = () => useContext(KeycloakContext);

// TopBar Component for search and settings
const TopBar = () => {
  const navigate = useNavigate();

  const { keycloak } = useKeycloak();

  const handleLogout = () => {
    keycloak.logout();
  };

  const handleSettings = () => {
    navigate('settings');
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {/* Placeholder for additional elements (if needed) */}
      <div />

      {/* Settings and Logout Icons */}
      <div style={{ display: 'flex', gap: '16px', paddingTop: '20px' }}>
        <SettingOutlined
          style={{
            fontSize: '18px',
            cursor: 'pointer',
            color: '#fff',
          }}
          onClick={handleSettings}
        />
        <LogoutOutlined
          style={{
            fontSize: '18px',
            cursor: 'pointer',
            color: '#fff',
          }}
          onClick={handleLogout}
        />
      </div>
    </div>
  );
};

// Main App Content with Sidebar and Routing
const AppContent = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [openKeys, setOpenKeys] = useState([]); // Manage open submenu keys
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname.substring(1));

  const breadcrumbItems = generateBreadcrumbItems(menuItems, location.pathname);

  useEffect(() => {
    setCurrent(location.pathname.substring(1));
    const pathSegments = location.pathname.split('/').filter(Boolean);
    if (pathSegments.length > 1) {
      setOpenKeys([pathSegments[0]]);
    }
  }, [location]);

  const handleMenuClick = (e) => {
    setCurrent(e.key);
    navigate(`/${e.key}`);
    setDrawerVisible(false);
  };

  const handleOpenChange = (keys) => {
    setOpenKeys(keys); // Update openKeys state when submenu is toggled
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* Top Bar */}
      <Header className="ant-layout-header ant-layout-header-shadow">
        <TopBar toggleDrawer={toggleDrawer} />
      </Header>

      {/* Main Layout with Sidebar and Content */}
      <Layout>
        <Drawer
          title="Menu"
          placement="left"
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          style={{ padding: 0 }}
        >
          <Menu
            mode="inline"
            selectedKeys={[current]}
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            onClick={handleMenuClick}
            style={{ height: '100%', borderRight: 0 }}
            items={menuItems
              .map((menu) => {
                // Filter out hidden children
                const visibleChildren =
                  menu.children?.filter((child) => !child.hidden) || [];

                // If all children are hidden, remove the children array
                if (visibleChildren.length === 0) {
                  return { ...menu, children: undefined };
                }

                // Return the menu with visible children only
                return { ...menu, children: visibleChildren };
              })
              .filter((menu) => !menu.hidden)} // Filter hidden main menus if needed
          />
        </Drawer>

        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          style={{ display: drawerVisible ? 'none' : 'block' }}
          className="site-layout-background"
        >
          <Menu
            mode="inline"
            selectedKeys={[current]}
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            onClick={handleMenuClick}
            style={{ height: '100%', borderRight: 0 }}
            items={menuItems
              .map((menu) => {
                // Filter out hidden children
                const visibleChildren =
                  menu.children?.filter((child) => !child.hidden) || [];

                // If all children are hidden, remove the children array
                if (visibleChildren.length === 0) {
                  return { ...menu, children: undefined };
                }

                // Return the menu with visible children only
                return { ...menu, children: visibleChildren };
              })
              .filter((menu) => !menu.hidden)} // Filter hidden main menus if needed
          />
        </Sider>

        {/* Content Area */}
        <Layout style={{ padding: '0 2px' }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: '#fff',
            }}
          >
            <BreadcrumbComponent items={breadcrumbItems} />

            <Routes>
              <Route path="/" element={<Start />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/contacts/create" element={<CreateContacts />} />
              <Route path="/contacts/edit/:id" element={<CreateContacts />} />
              <Route path="/invoices/create" element={<CreateInvoice />} />
              <Route path="/invoices" element={<InvoicesOverview />} />
              <Route path="/invoices/edit/:id" element={<CreateInvoice />} />
              <Route path="/accounting/overview" element={<Overview />} />

              <Route
                path="/accounting/transactions"
                element={<TransactionList />}
              />
              <Route
                path="/accounting/transactions/create"
                element={<CreateTransaction />}
              />
              <Route
                path="/accounting/transactions/edit/:transactionId"
                element={<EditTransaction />}
              />
              <Route
                path="/accounting/year-end-closing"
                element={<YearEnd />}
              />

              <Route path="/payments/create" element={<CreatePayment />} />
              <Route path="/payments/receive" element={<CamtChecker />} />
              <Route path="/projects" element={<ProjectForm />} />
              <Route path="/projects/create" element={<CreateProject />} />
              <Route path="/projects/edit/:id" element={<CreateProject />} />
              <Route path="/settings" element={<Setting />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

const App = () => {
  const [authenticated, setAuthenticated] = useState(null);
  const [keycloakInitialized, setKeycloakInitialized] = useState(false);
  const keycloakInstance = useRef(null);

  useEffect(() => {
    if (!keycloakInstance.current) {
      keycloakInstance.current = new Keycloak({
        url: process.env.REACT_APP_KEYCLOAK_URL,
        realm: process.env.REACT_APP_KEYCLOAK_REALM,
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
      });

      keycloakInstance.current
        .init({ onLoad: 'login-required' })
        .then((auth) => {
          setAuthenticated(auth);
          setKeycloakInitialized(true);

          if (!auth) {
            keycloakInstance.current.login();
          } else {
            // Set the initial Authorization header
            axios.defaults.headers.common['Authorization'] =
              `Bearer ${keycloakInstance.current.token}`;

            // Start a periodic token refresh
            const refreshInterval = setInterval(async () => {
              try {
                const refreshed =
                  await keycloakInstance.current.updateToken(30); // Refresh if token will expire in 30 seconds
                if (refreshed) {
                  axios.defaults.headers.common['Authorization'] =
                    `Bearer ${keycloakInstance.current.token}`;
                }
              } catch (err) {
                console.error('Failed to refresh Keycloak token', err);
                keycloakInstance.current.login(); // Redirect to login if token refresh fails
              }
            }, 10000); // Check every 10 seconds

            // Cleanup interval on component unmount
            return () => clearInterval(refreshInterval);
          }
        })
        .catch((err) => {
          console.error('Failed to initialize Keycloak', err);
          setAuthenticated(false);
          setKeycloakInitialized(true);
        });
    }
  }, []);

  if (!keycloakInitialized) {
    return <div>Loading...</div>;
  }

  if (authenticated === false) {
    return <div>Not authenticated. Redirecting to login...</div>;
  }

  return (
    <KeycloakContext.Provider
      value={{ keycloak: keycloakInstance.current, authenticated }}
    >
      <Router>
        <AppContent />
      </Router>
    </KeycloakContext.Provider>
  );
};

export default App;
