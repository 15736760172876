import React, { useState, useEffect } from 'react';
import { Table, Button, message, Row, Col, Popconfirm } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface Contact {
  _id: string;
  name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
}

const Contacts: React.FC = () => {
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axios.get<Contact[]>(
        `${process.env.REACT_APP_API_BASE_URL}/contacts`,
      );
      setFilteredContacts(response.data);
    } catch (error) {
      console.error('Error fetching contacts', error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/contacts/${id}`,
      );
      message.success('Contact deleted successfully');
      fetchContacts(); // Refresh contacts after deletion
    } catch (error) {
      console.error('Error deleting contact', error);
      message.error('Failed to delete contact.');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Postal Code',
      dataIndex: 'postalCode',
      key: 'postalCode',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_text: string, record: Contact) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            type="link"
            onClick={() => navigate(`/contacts/edit/${record._id}`)}
          >
            <EditOutlined style={{ color: '#1890ff' }} />
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this contact?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div style={{ alignContent: 'left', paddingLeft: '20px' }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: '20px' }}
      >
        <Col>
          <h1>Contacts Overview</h1>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate('/contacts/create')}
          >
            Create Contact
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredContacts}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
        style={{ marginTop: '20px' }}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default Contacts;
