import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import type { Contact } from './contactsSlice';
import axios from 'axios';

interface Recurrence {
  type: 'weekly' | 'monthly' | 'yearly';
  endDate: string;
}

// Invoice Item Interface
export interface InvoiceItem {
  description: string;
  quantity: number;
  price: number;
  projectId: string;
}

// Invoice Interface
interface Invoice {
  _id?: string;
  contact: Contact;
  items: InvoiceItem[];
  dueDate: string;
  invoiceDate: string;
  recurrence?: Recurrence;
  files?: any;
  totalAmount: number;
  status: 'Paid' | 'Unpaid';
}

// Invoice State Interface
interface InvoiceState {
  invoices: Invoice[];
  invoiceData: Invoice | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error?: string;
}

// Initial State
const initialState: InvoiceState = {
  invoices: [],
  invoiceData: null,
  status: 'idle',
  error: undefined,
};

export const fetchInvoice = createAsyncThunk<Invoice, string>(
  'invoice/fetchInvoice',
  async (invoiceId, { rejectWithValue }) => {
    try {
      const response = await axios.get<Invoice>(
        `${process.env.REACT_APP_API_BASE_URL}/invoices/${invoiceId}`,
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);
// ✅ Save or Update an Invoice
export const saveInvoice = createAsyncThunk<
  void,
  { invoiceId?: string; invoiceData: Invoice }
>(
  'invoice/saveInvoice',
  async ({ invoiceId, invoiceData }, { rejectWithValue }) => {
    try {
      const url = invoiceId
        ? `${process.env.REACT_APP_API_BASE_URL}/invoices/${invoiceId}`
        : `${process.env.REACT_APP_API_BASE_URL}/invoices`;

      const method = invoiceId ? 'put' : 'post';

      await axios[method](url, invoiceData);
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

// ✅ Fetch all invoices
export const fetchInvoices = createAsyncThunk<Invoice[]>(
  'invoices/fetchInvoices',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<Invoice[]>(
        `${process.env.REACT_APP_API_BASE_URL}/invoices`,
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

// ✅ Delete an invoice by ID
export const deleteInvoice = createAsyncThunk<string, string>(
  'invoices/deleteInvoice',
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/invoices/${id}`,
      );
      return id;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

// Create Invoice Slice
const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    resetInvoice: (state) => {
      state.invoiceData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchInvoice.fulfilled,
        (state, action: PayloadAction<Invoice>) => {
          state.invoiceData = action.payload;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchInvoice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(saveInvoice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveInvoice.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(saveInvoice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      // ✅ Fetch Invoices
      .addCase(fetchInvoices.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchInvoices.fulfilled,
        (state, action: PayloadAction<Invoice[]>) => {
          state.invoices = action.payload;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchInvoices.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      // ✅ Delete Invoice
      .addCase(
        deleteInvoice.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.invoices = state.invoices.filter(
            (invoice) => invoice._id !== action.payload,
          );
        },
      );
  },
});

export const { resetInvoice } = invoiceSlice.actions;
export default invoiceSlice.reducer;
