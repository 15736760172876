import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, notification } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';

const SettingsForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [settingsId, setSettingsId] = useState(null);

  // Fetch settings from API
  const fetchSettings = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/settings`,
      );

      if (response.data && response.data.length > 0) {
        const settingsData = response.data[0];
        setSettingsId(settingsData._id);

        form.setFieldsValue({
          ...settingsData,
          bookingPeriods: settingsData.bookingPeriods
            ? settingsData.bookingPeriods.map((period) => ({
                name: period.name,
                range: [dayjs(period.start), dayjs(period.end)],
              }))
            : [],
        });
      } else {
        setSettingsId(null);
      }
    } catch (err) {
      console.error('Error fetching settings:', err);
      notification.error({ message: 'Failed to load settings' });
    } finally {
      setLoading(false);
    }
  };

  // Save settings (Update if settings exist, Create if new)
  const handleSave = async () => {
    try {
      setSaving(true);
      const updatedSettings = form.getFieldsValue();

      // Ensure all booking periods are correctly formatted
      updatedSettings.bookingPeriods = updatedSettings.bookingPeriods.map(
        (period) => {
          const [start, end] = period.range;
          return {
            name: period.name,
            start: start.format('YYYY-MM-DD'),
            end: end.format('YYYY-MM-DD'),
          };
        },
      );

      if (settingsId) {
        await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/settings/${settingsId}`,
          updatedSettings,
        );
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/settings`,
          updatedSettings,
        );
        setSettingsId(response.data._id);
      }

      notification.success({ message: 'Settings saved successfully' });
      fetchSettings();
    } catch (err) {
      console.error('Error saving settings:', err);
      notification.error({ message: 'Failed to save settings' });
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <div style={{ paddingLeft: '20px', maxWidth: '800px' }}>
      <h1>Settings</h1>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleSave}
        onValuesChange={(changedValues, allValues) => {
          // Handle form value changes here if necessary
          console.log(changedValues, allValues);
        }}
        style={{ maxWidth: '800px', marginBottom: '20px' }}
      >
        <Form.Item
          label="IBAN"
          name="iban"
          rules={[{ required: true, message: 'Please input IBAN!' }]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <Form.Item
          label="Account Name"
          name="accountName"
          rules={[{ required: true, message: 'Please input Account Name!' }]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <Form.Item
          label="BIC"
          name="bic"
          rules={[{ required: true, message: 'Please input BIC!' }]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <Form.Item
          label="Invoice Due Days"
          name="invoiceDueDays"
          rules={[
            { required: true, message: 'Please input Invoice Due Days!' },
          ]}
        >
          <Input type="number" disabled={loading} />
        </Form.Item>

        <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true, message: 'Please input Address!' }]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <Form.Item
          label="Building Number"
          name="buildingNumber"
          rules={[{ required: true, message: 'Please input Building Number!' }]}
        >
          <Input type="number" disabled={loading} />
        </Form.Item>

        <Form.Item
          label="ZIP"
          name="zip"
          rules={[{ required: true, message: 'Please input ZIP!' }]}
        >
          <Input type="number" disabled={loading} />
        </Form.Item>

        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: 'Please input City!' }]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <Form.Item
          label="Country"
          name="country"
          rules={[{ required: true, message: 'Please input Country!' }]}
        >
          <Input disabled={loading} />
        </Form.Item>

        {/* Booking Periods */}
        <Form.List name="bookingPeriods">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  key={key}
                  style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                    rules={[{ required: true, message: 'Enter a name' }]}
                    style={{ flex: 1 }}
                  >
                    <Input placeholder="Period Name" disabled={loading} />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, 'range']}
                    rules={[{ required: true, message: 'Select dates' }]}
                    style={{ flex: 2 }}
                  >
                    <DatePicker.RangePicker disabled={loading} />
                  </Form.Item>

                  <Button type="link" danger onClick={() => remove(name)}>
                    <DeleteOutlined />
                  </Button>
                </div>
              ))}
              <Button
                type="dashed"
                onClick={() =>
                  add({ name: '', range: [dayjs(), dayjs().add(7, 'day')] })
                }
                icon={<PlusOutlined />}
                style={{ marginTop: '10px' }}
              >
                Add Booking Period
              </Button>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={saving}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SettingsForm;
