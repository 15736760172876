import React, { useState, useEffect, useCallback } from 'react';
import { Table, Spin, Select, Typography, Tag } from 'antd';
import axios from 'axios';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Option } = Select;

interface Account {
  accountName: string;
  accountType: 'asset' | 'liability' | 'equity' | 'revenue' | 'expense';
  accountBalance: { [year: string]: number };
}

interface OverviewData {
  balanceSheet: {
    assets: Account[];
    liabilities: Account[];
    equity: Account[];
    totalLiabilitiesAndEquity: { [year: string]: number };
  };
  incomeStatement: {
    expenses: Account[];
    revenue: Account[];
    netIncome: { [year: string]: number };
  };
}

const Overview: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const [overview, setOverview] = useState<OverviewData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedYears, setSelectedYears] = useState<string[]>([
    currentYear.toString(),
  ]);

  // Ensure selected years are always in descending order
  const sortedYears = [...selectedYears].sort(
    (a, b) => parseInt(b) - parseInt(a),
  );

  useEffect(() => {
    async function fetchOverview() {
      setLoading(true);
      try {
        const responses = await Promise.all(
          sortedYears.map((year) =>
            axios.get<OverviewData>(
              `${process.env.REACT_APP_API_BASE_URL}/accounts-overview?year=${year}`,
            ),
          ),
        );

        const mergedOverview: OverviewData = {
          balanceSheet: {
            assets: [],
            liabilities: [],
            equity: [],
            totalLiabilitiesAndEquity: {},
          },
          incomeStatement: {
            revenue: [],
            expenses: [],
            netIncome: {},
          },
        };

        responses.forEach((res, index) => {
          const year = sortedYears[index];

          const mergeAccounts = (
            existingAccounts: Account[],
            newAccounts: Account[],
          ) => {
            const updatedAccounts = [...existingAccounts];

            newAccounts.forEach((newAcc) => {
              let existingAcc = updatedAccounts.find(
                (acc) => acc.accountName === newAcc.accountName,
              );

              if (!existingAcc) {
                existingAcc = {
                  ...newAcc,
                  accountBalance: {},
                };
                updatedAccounts.push(existingAcc);
              }

              sortedYears.forEach((y) => {
                existingAcc!.accountBalance[y] =
                  existingAcc!.accountBalance[y] ?? 0;
              });

              existingAcc.accountBalance[year] =
                newAcc.accountBalance[year] ?? 0;
            });

            return updatedAccounts;
          };

          mergedOverview.balanceSheet.assets = mergeAccounts(
            mergedOverview.balanceSheet.assets,
            res.data.balanceSheet.assets,
          );
          mergedOverview.balanceSheet.liabilities = mergeAccounts(
            mergedOverview.balanceSheet.liabilities,
            res.data.balanceSheet.liabilities,
          );
          mergedOverview.balanceSheet.equity = mergeAccounts(
            mergedOverview.balanceSheet.equity,
            res.data.balanceSheet.equity,
          );

          mergedOverview.incomeStatement.revenue = mergeAccounts(
            mergedOverview.incomeStatement.revenue,
            res.data.incomeStatement.revenue,
          );
          mergedOverview.incomeStatement.expenses = mergeAccounts(
            mergedOverview.incomeStatement.expenses,
            res.data.incomeStatement.expenses,
          );

          mergedOverview.balanceSheet.totalLiabilitiesAndEquity[year] =
            res.data.balanceSheet.totalLiabilitiesAndEquity[year] ?? 0;

          mergedOverview.incomeStatement.netIncome[year] =
            res.data.incomeStatement.netIncome[year] ?? 0;
        });

        setOverview(mergedOverview);
      } catch (error) {
        console.error('Error fetching overview', error);
      } finally {
        setLoading(false);
      }
    }
    fetchOverview();
  }, [selectedYears]);

  const createColumns = (years: string[]) => {
    const columns: any[] = [
      {
        title: 'Account Name',
        dataIndex: 'accountName',
        key: 'accountName',
        fixed: 'left' as const, // Fixes TypeScript error
      },
      ...years.map((year) => ({
        title: `${year}`,
        key: year,
        render: (_: any, record: Account) => {
          const balance = record.accountBalance[year] ?? 0;
          return `CHF ${balance.toFixed(2)}`;
        },
      })),
    ];

    // Add percentage change column for each previous year comparison
    for (let i = 0; i < years.length - 1; i++) {
      const currentYear = years[i];
      const prevYear = years[i + 1];

      columns.push({
        title: `Change (${prevYear} → ${currentYear})`,
        key: `change-${prevYear}-${currentYear}`,
        render: (_: any, record: Account) => {
          const value1 = record.accountBalance[prevYear] ?? 0;
          const value2 = record.accountBalance[currentYear] ?? 0;

          if (value1 === 0) return '-'; // Avoid division by zero

          const change = ((value2 - value1) / Math.abs(value1)) * 100;
          const isPositive = change > 0;
          return (
            <Tag color={isPositive ? 'green' : 'red'}>
              {isPositive ? <ArrowUpOutlined /> : <ArrowDownOutlined />}{' '}
              {change.toFixed(2)}%
            </Tag>
          );
        },
      });
    }

    return columns;
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ paddingLeft: '20px' }}>
      <h1>Balance & Income Statement</h1>
      <Select
        mode="multiple"
        value={selectedYears}
        onChange={(values) => setSelectedYears(values)}
        style={{ width: 200, marginRight: '20px' }}
      >
        {[...Array(5)].map((_, index) => {
          const year = currentYear - index;
          return (
            <Option key={year} value={year.toString()}>
              {year}
            </Option>
          );
        })}
      </Select>

      <Title level={3}>Balance Sheet</Title>
      <Table
        dataSource={overview?.balanceSheet.assets || []}
        columns={createColumns(sortedYears)}
        pagination={false}
      />

      <Title level={3}>Income Statement</Title>
      <Table
        dataSource={overview?.incomeStatement.revenue || []}
        columns={createColumns(sortedYears)}
        pagination={false}
      />
    </div>
  );
};

export default Overview;
