import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { Table, Button, Tag, Row, Col, Popconfirm, message } from 'antd';
import {
  CheckCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import axios from 'axios';

const Invoices: React.FC = () => {
  const [invoices, setInvoices] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/invoices`,
        );
        const fetchedInvoices = Array.isArray(response.data)
          ? response.data
          : Array.isArray(response.data.invoices)
            ? response.data.invoices
            : [];
        setInvoices(fetchedInvoices);
      } catch (error) {
        console.error('Error fetching invoices:', error);
        setInvoices([]);
      }
    };

    fetchInvoices();
  }, []);

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/invoices/${id}`,
      );
      setInvoices((prev) => prev.filter((invoice) => invoice._id !== id));
      message.success('Invoice deleted successfully');
    } catch (error) {
      console.error('Error deleting invoice', error);
      message.error('Failed to delete invoice');
    }
  };

  const handleDownload = async (invoiceId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/invoices/${invoiceId}/pdf`,
        { responseType: 'blob' },
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `invoice-${invoiceId}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      sorter: (a, b) => a.invoiceNumber.localeCompare(b.invoiceNumber),
      filters: invoices.map((invoice) => ({
        text: invoice.invoiceNumber,
        value: invoice.invoiceNumber,
      })),
      onFilter: (value, record) => String(record.status) === String(value),
    },
    {
      title: 'Contact Name',
      dataIndex: ['contact', 'name'],
      key: 'contact.name',
      sorter: (a: any, b: any) => a.contact.name.localeCompare(b.contact.name),
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text: number) => `${text.toFixed(2)} CHF`,
      sorter: (a: any, b: any) => a.totalAmount - b.totalAmount,
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      render: (text: string) => new Date(text).toLocaleDateString(),
      sorter: (a: any, b: any) =>
        new Date(a.invoiceDate).getTime() - new Date(b.invoiceDate).getTime(),
    },
    {
      title: 'Invoice Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag
          icon={
            status === 'Paid' ? (
              <CheckCircleOutlined />
            ) : (
              <CloseCircleOutlined />
            )
          }
          color={status === 'Paid' ? 'green' : 'red'}
        >
          {status}
        </Tag>
      ),
      filters: [
        { text: 'Paid', value: 'Paid' },
        { text: 'Unpaid', value: 'Unpaid' },
      ],
      onFilter: (value, record) => String(record.status) === String(value),
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (text: string) => new Date(text).toLocaleDateString(),
      sorter: (a: any, b: any) =>
        new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: any) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Link to={`/invoices/edit/${record._id}`}>
            <EditOutlined style={{ color: '#1890ff', cursor: 'pointer' }} />
          </Link>
          <Popconfirm
            title="Are you sure you want to delete this invoice?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
          </Popconfirm>
          <DownloadOutlined
            style={{ cursor: 'pointer', color: '#1890ff', fontSize: '18px' }}
            onClick={() => handleDownload(record._id)}
          />
        </div>
      ),
    },
  ];

  return (
    <div style={{ alignContent: 'left', paddingLeft: '20px' }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: '20px' }}
      >
        <Col>
          <h1>Invoices Overview</h1>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate('/invoices/create')}
          >
            Create Invoice
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={invoices}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default Invoices;
