import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Popconfirm, message, Button, Row, Col, Tooltip } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  FileOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import axios from 'axios';

function TransactionList() {
  const [transactions, setTransactions] = useState([]);
  const [accounts, setAccounts] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchTransactions() {
      try {
        const transactionResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/transactions`,
        );
        const transactions = transactionResponse.data;

        const accountIds = [
          ...new Set(
            transactions.flatMap((t) => [t.debitAccount, t.creditAccount]),
          ),
        ];

        const accountResponses = await Promise.all(
          accountIds.map((id) =>
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/accounts/${id}`),
          ),
        );

        const accountsMap = accountResponses.reduce((acc, response) => {
          const account = response.data;
          acc[account._id] = account;
          return acc;
        }, {});

        setAccounts(accountsMap);
        setTransactions(transactions);
      } catch (error) {
        console.error('Error fetching transactions or accounts', error);
      }
    }
    fetchTransactions();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/transactions/${id}`,
      );
      setTransactions(transactions.filter((t) => t._id !== id));
      message.success('Transaction deleted successfully');
    } catch (error) {
      console.error('Error deleting transaction', error);
      message.error('Failed to delete transaction');
    }
  };

  const handleDownload = (documentPath) => {
    const filename = documentPath.split('/').pop();
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/${documentPath}`,
      method: 'GET',
      responseType: 'blob', // Important for file download
    })
      .then((response) => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch((error) => {
        console.error('Error downloading document:', error);
        message.error('Failed to download document');
      });
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Debit Account',
      dataIndex: 'debitAccount',
      key: 'debit',
      render: (accountId) => {
        const account = accounts[accountId];
        return account
          ? `${account.accountName} (${account.accountNumber})`
          : accountId;
      },
    },
    {
      title: 'Credit Account',
      dataIndex: 'creditAccount',
      key: 'credit',
      render: (accountId) => {
        const account = accounts[accountId];
        return account
          ? `${account.accountName} (${account.accountNumber})`
          : accountId;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Document',
      key: 'document',
      render: (text, record) =>
        record.document ? (
          <Tooltip title="Download document">
            <DownloadOutlined
              style={{ color: '#1890ff', cursor: 'pointer' }}
              onClick={() => handleDownload(record.document)}
            />
          </Tooltip>
        ) : (
          <Tooltip title="No document available">
            <FileOutlined style={{ color: '#d9d9d9', cursor: 'not-allowed' }} />
          </Tooltip>
        ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Link to={`/accounting/transactions/edit/${record._id}`}>
            <EditOutlined style={{ color: '#1890ff', cursor: 'pointer' }} />
          </Link>
          <Popconfirm
            title="Are you sure you want to delete this transaction?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div style={{ alignContent: 'left', paddingLeft: '20px' }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: '20px' }}
      >
        <Col>
          <h1>Transactions</h1>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate('/accounting/transactions/create')}
          >
            Create Transaction
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={transactions}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
}

export default TransactionList;
