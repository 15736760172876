import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store/store';
import dayjs from 'dayjs';

interface BookingPeriod {
  name: string;
  range: [dayjs.Dayjs, dayjs.Dayjs];
}

interface SettingsState {
  settings: {
    iban: string;
    accountName: string;
    bic: string;
    invoiceDueDays: number;
    address: string;
    buildingNumber: string;
    zip: string;
    city: string;
    country: string;
    duetime: string;
    taxNumber: string;
    email: string;
    phone: string;
    website: string;
  } | null;
  bookingPeriods: BookingPeriod[];
  loading: boolean;
  saving: boolean;
}

const initialState: SettingsState = {
  settings: null,
  bookingPeriods: [],
  loading: false,
  saving: false,
};

export const fetchSettings = createAsyncThunk(
  'settings/fetchSettings',
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/settings`,
    );
    return response.data[0];
  },
);

export const saveSettings = createAsyncThunk(
  'settings/saveSettings',
  async (updatedSettings: any) => {
    await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/settings/6756002784e4f71901276b13`,
      updatedSettings,
    );
    return updatedSettings;
  },
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setBookingPeriods: (state, action: PayloadAction<BookingPeriod[]>) => {
      state.bookingPeriods = action.payload;
    },
    updateBookingPeriod: (
      state,
      action: PayloadAction<{
        index: number;
        field: keyof BookingPeriod;
        value: any;
      }>,
    ) => {
      const { index, field, value } = action.payload;
      state.bookingPeriods = state.bookingPeriods.map((period, i) =>
        i === index ? { ...period, [field]: value } : period,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload;
        state.bookingPeriods = action.payload.bookingPeriods || [];
      })
      .addCase(fetchSettings.rejected, (state) => {
        state.loading = false;
      })
      .addCase(saveSettings.pending, (state) => {
        state.saving = true;
      })
      .addCase(saveSettings.fulfilled, (state, action) => {
        state.saving = false;
        state.settings = action.payload;
      })
      .addCase(saveSettings.rejected, (state) => {
        state.saving = false;
      });
  },
});

export const { setBookingPeriods, updateBookingPeriod } = settingsSlice.actions;
export default settingsSlice.reducer;
export const selectSettings = (state: RootState) => state.settings;
