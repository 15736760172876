import React, { useEffect } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import {
  fetchContacts,
  fetchProjectDetails,
  createProject,
  updateProject,
} from '../store/projectSlice';

const { Option } = Select;

const CreateProject: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { contacts, projectDetails, loading, error } = useSelector(
    (state: RootState) => state.projects,
  );
  const isEditMode = !!id;

  useEffect(() => {
    dispatch(fetchContacts());
    if (id) {
      dispatch(fetchProjectDetails(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (projectDetails && isEditMode) {
      form.setFieldsValue({
        name: projectDetails.name,
        description: projectDetails.description,
        status: projectDetails.status,
        price: projectDetails.price,
        contacts: projectDetails.contacts.map((contact) => contact._id),
      });
    }
  }, [projectDetails, isEditMode, form]);

  useEffect(() => {
    if (error) {
      message.error('Failed to fetch data');
    }
  }, [error]);

  const handleSubmit = async (values: any) => {
    try {
      const projectData = {
        name: values.name,
        description: values.description,
        status: values.status,
        price: values.price,
        contacts: values.contacts,
      };

      if (isEditMode) {
        await dispatch(updateProject({ id, projectData })).unwrap();
        message.success('Project updated successfully');
      } else {
        await dispatch(createProject(projectData)).unwrap();
        message.success('Project created successfully');
      }

      navigate('/projects');
    } catch (error) {
      message.error('Failed to save project');
    }
  };

  return (
    <div style={{ paddingLeft: '20px', maxWidth: '600px' }}>
      <h1>{isEditMode ? 'Edit Project' : 'Create Project'}</h1>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          status: 'Ongoing', // Default status
        }}
      >
        <Form.Item
          label="Project Name"
          name="name"
          rules={[{ required: true, message: 'Please enter a project name' }]}
        >
          <Input placeholder="Enter project name" />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: 'Please enter a description' }]}
        >
          <Input.TextArea placeholder="Enter project description" rows={4} />
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, message: 'Please enter a price' }]}
        >
          <Input placeholder="Enter a price" />
        </Form.Item>

        <Form.Item
          label="Status"
          name="status"
          rules={[{ required: true, message: 'Please select a status' }]}
        >
          <Select placeholder="Select status">
            <Option value="Ongoing">Ongoing</Option>
            <Option value="Completed">Completed</Option>
            <Option value="Pending">Pending</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Contacts"
          name="contacts"
          rules={[
            { required: true, message: 'Please select at least one contact' },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Select contacts"
            optionFilterProp="children"
          >
            {contacts.map((contact) => (
              <Option key={contact._id} value={contact._id}>
                {contact.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Button type="primary" htmlType="submit" loading={loading}>
          {isEditMode ? 'Update Project' : 'Create Project'}
        </Button>
      </Form>
    </div>
  );
};

export default CreateProject;
