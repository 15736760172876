import { InboxOutlined } from '@ant-design/icons';
import { Form, Input, Button, Upload, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchContact,
  createContact,
  updateContact,
} from '../store/contactsSlice';
import { AppDispatch, RootState } from '../store/store';
import { useParams, useNavigate } from 'react-router-dom';

const { Dragger } = Upload;

export interface Contact {
  _id?: string;
  name: string;
  email: string;
  phone: string;
  address?: string;
  city?: string;
  postalCode?: string;
  country?: string;
  documentPath?: string; // ✅ Ensure this property exists
}

export default function CreateContacts() {
  const { id } = useParams<{ id?: string }>(); // ✅ Get contact ID from URL
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // ✅ Get contact from Redux if editing
  const contact = useSelector((state: RootState) =>
    id ? state.contacts.contacts.find((c) => c._id === id) : null,
  );

  const [fileList, setFileList] = useState<any[]>([]);

  useEffect(() => {
    if (id) {
      dispatch(fetchContact(id)); // ✅ Fetch contact if editing
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        address: contact.address,
        city: contact.city,
        postalCode: contact.postalCode,
        country: contact.country,
      });

      // ✅ Set existing document if available
      if (contact.documentPath) {
        setFileList([
          {
            uid: '-1',
            name: contact.documentPath.split('/').pop() || 'document.pdf', // ✅ Extract file name safely
            status: 'done',
            url: contact.documentPath,
          },
        ]);
      }
    }
  }, [contact, form]);

  const handleSubmit = async (values: any) => {
    try {
      const formData = new FormData();

      // ✅ Append form fields safely
      Object.entries(values).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value as string);
        }
      });

      // ✅ Append document file if uploaded
      if (fileList.length > 0 && fileList[0].originFileObj) {
        formData.append('document', fileList[0].originFileObj);
      }

      if (id) {
        await dispatch(updateContact({ id, contactData: formData })).unwrap();
        notification.success({
          message: 'Success',
          description: 'Contact updated successfully',
        });
      } else {
        await dispatch(createContact(formData)).unwrap();
        form.resetFields();
        setFileList([]);
        notification.success({
          message: 'Success',
          description: 'Contact created successfully',
        });
      }
      navigate('/contacts');
    } catch (error) {
      console.error('Error saving contact', error);
      notification.error({
        message: 'Error',
        description: 'Failed to save contact',
      });
    }
  };

  return (
    <div
      style={{ alignContent: 'left', paddingLeft: '20px', maxWidth: '600px' }}
    >
      <h1>{id ? 'Edit Contact' : 'Create Contact'}</h1>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter the contact name' }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please enter the contact email' },
          ]}
        >
          <Input type="email" placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          rules={[
            { required: true, message: 'Please enter the contact phone' },
          ]}
        >
          <Input placeholder="Phone" />
        </Form.Item>
        <Form.Item name="address" label="Address">
          <Input placeholder="Address" />
        </Form.Item>
        <Form.Item name="city" label="City">
          <Input placeholder="City" />
        </Form.Item>
        <Form.Item name="postalCode" label="Postal Code">
          <Input placeholder="Postal Code" />
        </Form.Item>
        <Form.Item name="country" label="Country">
          <Input placeholder="Country" />
        </Form.Item>

        {/* ✅ File Upload Handling */}
        <Form.Item name="document" label="Upload Document">
          <Dragger
            beforeUpload={(file) => {
              setFileList([file]); // ✅ Store only the latest file
              return false; // ✅ Prevent auto-upload
            }}
            fileList={fileList}
            onRemove={() => {
              setFileList([]); // ✅ Allow removal of uploaded file
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p>Click or drag file to upload</p>
          </Dragger>

          {/* ✅ Show existing document if available */}
          {contact?.documentPath && !fileList.length && (
            <div style={{ marginTop: '10px' }}>
              <a
                href={`http://yourdomain.com${contact.documentPath}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                📄 View Existing Document
              </a>
            </div>
          )}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {id ? 'Update Contact' : 'Add Contact'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
