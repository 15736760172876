import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const BreadcrumbComponent = ({ items }) => {
  const breadcrumbItems = items.map((item, index) => ({
    key: index,
    title: item.active ? item.label : <Link to={item.path}>{item.label}</Link>,
  }));

  return (
    <Breadcrumb
      items={breadcrumbItems}
      style={{ alignContent: 'left', paddingLeft: '20px' }}
    />
  );
};

export default BreadcrumbComponent;
