import { configureStore } from '@reduxjs/toolkit';
import invoiceReducer from './invoiceSlice';
import contactsReducer from './contactsSlice';
import projectReducer from './projectSlice';
import yearEndReducer from './yearEndSlice';
import settingsReducer from './settingsSlice';
import transactionReducer from './transactionSlice';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

export const store = configureStore({
  reducer: {
    contacts: contactsReducer,
    invoice: invoiceReducer,
    projects: projectReducer,
    yearEnd: yearEndReducer,
    settings: settingsReducer,
    transaction: transactionReducer,
  },
});

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
